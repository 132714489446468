import React, { useEffect } from "react";
import classes from "./UnSubSuccessPage.module.css";
import { useLocation, useNavigate } from "react-router-dom";

const UnSubSuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location?.state?.show) {
      navigate("/subscription");
    }
  }, []);
  return (
    <div className={classes.container}>
      <div class={`${classes.success}`}>
        <div className={classes.login_logo}>
          {/* <img src={img_logo} /> */}
       
        </div>
        <div className={classes.main}>
        <img
            src="/assets/images/mainLogo.jpeg"
            style={{ width: "300px", height: "70px", objectFit: "contain" }}
          />
          <div className={classes.message_box_container}>
            <div className={classes.message_box}>
              <p className={classes.message}>
                You have successfully unsubscribed from the service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnSubSuccessPage;
